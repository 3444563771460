#cart {
    .cart-grid-right {
        .card-body {
        }
        .card-footer {
            border-top: none;
            .cart-detailed-actions {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @media (max-width: 992px) {
                    position: relative;
                    background-color: $masamune-white-2;
                }
            }
        }
    }
}