@include media-breakpoint-desktop {
  .product-comment-modal .modal-dialog{
    max-width: 774px;
  }
}
.product-comment-modal{
  .modal-footer{
    margin-right: -$modal-inner-padding;
    margin-left: -$modal-inner-padding;
  }
  .modal-body{
    padding-bottom: 0;
  }
}
.modal--product-comment-posted-modal,
.modal--report-comment-posted{
  .modal-header{
    color:$success
  }
}
.product-comment-post-error-message{
  .modal-header{
    color:$danger
  }
}
.grade-stars,
.comments-note{
  display: flex;
}
.star{
  color: $masamune-black-1;
}

/** product list **/

.product-list-reviews{
  #index &, #product & {
    display: none;
  }
  .grade-stars{
 justify-content: center;
  }
  .star{
    .material-icons{
      font-size: $font-size-base;
    }
  }
}
/** product page **/
.product-comments{
  margin-top: $spacer*2;
  margin-bottom: $spacer*2;
}
.product-comments-additional-info{
  margin: $spacer 0;
}
.comments-note{
  .grade-stars{
    margin-left: $spacer/4;
  }
  margin-bottom: $spacer/2;
}
.comments__header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-comment-list-item{
  background: $white;
  margin-bottom: $spacer;
  padding: $spacer;
}
.comment-infos{
  .grade-stars{
    margin-bottom: $spacer;
  }
  .comment-date,
  .comment-author{
    font-size: $font-size-sm;
  }
}

/* comment list */
.product-comments__pagination{
  ul{
    @extend .pagination;
    @extend .pagination-sm;
  }
    li{
      @extend .page-item;
      span{
        @extend .page-link;
      }
      &:not(.disabled){
        span:not(.current){
          cursor: pointer;
        }
      }
    }
}
/* MODAL COMMENT */
/* radio input star ratig */
@import 'starability/starability-base';

.starability-basic {
  @include starability-base;
  min-height: auto;
}

.product-criterion{
  display: flex;
  justify-items: center;
  justify-content: flex-end;
  margin-bottom: $spacer;
  &__name{
    width: auto;
    font-size: $font-size-base;
    margin-bottom: 0;
    margin-right: $spacer/4;
  }
  label{
    margin-bottom: 0;
  }
}

#modal_comment, #modal_caracs, #modal_config {
  .modal-dialog {
    width: 700px;
    .modal-content {
      background-color: $masamune-white-2;
      padding: 51px;
      @media (max-width: 576px) {
        padding: 20px;
      }
      .modal-header {
        .xy-title {
          p {
            @media (max-width: 576px) {
              font-size: 25px;
            }
          }
        }
      }
      .modal-body {
        .feature_block {
          display: flex;
          margin-bottom: 15px;
          @media (max-width: 576px) {
            align-items: center;
          }
          h4 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 0;
            @media (max-width: 576px) {
              font-size: 18px;
            }
          }
          p {
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 400;
            padding-left: 20px;
            @media (max-width: 576px) {
              font-size: 18px;
            }
          }
        }
        .product-comments {
          margin: 0;
          .comments__header {
            display: none;
          }
          #product-comments-list {
            padding: 0;
            .product-comment-list-item {
              padding: 0;
              background-color: $masamune-white-2;
              margin-bottom: 38px;
              .row {
                margin: 0;
                flex-direction: column;
                .comment-author {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                  margin-bottom: 16px;
                }
                .comment-infos {
                  display: flex;
                  padding: 0;
                  .grade-stars {
                    margin-bottom: 0;
                    .star {
                      color: $masamune-black-1;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      i {
                        font-size: 14px;
                      }
                    }
                  }
                  .comment__title {
                    margin-bottom: 0;
                    margin-left: 5px;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                  }
                }
                .comment-content {
                  padding: 0;
                  margin-top: 9px;
                  p {
                    margin-bottom: 0;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
          #post-product-comment-form {
            #criterions_list {
              height: 100%;
              fieldset {
                height: 100%;
                .product-criterion {
                  margin-bottom: 0;
                  height: 100%;
                  .starability-basic {
                    select {
                      height: 100%;
                      width: 100%;
                      background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
                    }
                  }
                }
              }
            }
            input, textarea {
              border: 1px solid $masamune-black-1;
              &::placeholder {
                color: $masamune-black-1;
                opacity: 1;
              }
            }
            .post-comment-buttons {
              padding: 0;
              .btn-comment {
                width: 100%;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

#modal_config {
  .modal-content {
    .modal-header {
      padding: 0;
      margin-bottom: 50px;
    }
    .modal-body {
      padding: 0;
      .form-group {
        select {
          width: 100% !important;
          height: 60px;
        }
      }
    }
    .product-prices {
      p {
        margin: 0;
      }
    }
    .product-add-to-cart {
      .quantity-add-button {
          display: flex;
          align-items: center;
          .product-quantity {
              display: flex;
              flex-wrap: wrap;
              margin-top: 0;
              height: 100%;
              width: 20%;
              > div {
                  margin: 0;
              }
              .qty {
                  /* @media (max-width: $container-width-desktop-up) {
                      padding-right: 10px;
                      width: 40%;
                  }
                  @media (max-width: 1199px) {
                      width: 60%;
                  } */
                  .input-group {
                      box-shadow: none;
                      border: 1px solid $primary-color;
                      // Affichage horizontal des boutons du touchspin
                      .input-group-prepend {
                          display: none;
                          border-top-left-radius: 4px;
                          border-bottom-left-radius: 4px;
                          .btn {
                              border-top-left-radius: 4px;
                              border-bottom-left-radius: 4px;
                          }
                      }
                      .input-group-append {
                          display: none;
                          border-top-right-radius: 4px;
                          border-bottom-right-radius: 4px;
                          .btn {
                              border-top-right-radius: 4px;
                              border-bottom-right-radius: 4px;
                          }
                      }
                      .input-group-prepend,
                      .input-group-append {
                          border: 1px solid $grey-color-alt;
                          .btn {
                              border: none;
                              @media (max-width: $container-width-desktop-up) {
                                  padding: 0 10px;
                              }
                          }
                      }
                      // Affichage vertical des boutons du touchspin
                      .input-group-btn-vertical {
                          /*justify-content: center;*/
                          > .btn {
                              padding-right: 5px;
                          }
                          > .btn,
                          > .btn > i,
                          > .btn > span {
                              font-size: 17px;
                              line-height: 1;
                              font-weight: 700;
                              color: $body-color;
                          }
                      }
                  }
                  input[name="qty"] {
                      height: 50px;
                      max-width: 100%;
                      text-align: left;
                      border: none;
                      font-size: 14px;
                      font-weight: 400;
                      padding-left: 10px;
                      padding-right: 5px;
                      /* Firefox */
                      -moz-appearance: textfield;
                      /* Chrome, Safari, Edge, Opera */
                      &::-webkit-outer-spin-button,
                      &::-webkit-inner-spin-button {
                          -webkit-appearance: none;
                          margin: 0;
                      }
                      @media (max-width: $container-width-desktop-up) {
                          padding-left: 7px;
                          padding-right: 7px;
                      }
                  }
              }
              .add {
                  padding-left: 15px;
                  flex: 1;
                  .add-to-cart {
                      height: 50px;
                      font-size: 18px;
                      font-weight: 600;
                      padding: 10px 10px;
                      text-transform: none;
                      text-align: unset;
                      @media (max-width: 1199px) {
                          justify-content: center;
                      }
                      > i, > span {
                          font-size: 38px;
                      }
                      > .add-to-cart-txt {
                          font-size: 18px;
                          flex: 1 1 auto;
                          text-align: center;
                          @media (max-width: 1199px) {
                              display: none;
                          }
                      }
                  }
                  @media (max-width: $container-width-desktop-up) {
                      padding-left: 10px;
                      width: 60%;
                      .add-to-cart {
                          i {
                              display: none;
                          }
                      }
                  }
                  @media (max-width: 1199px) {
                      width: 40%;
                      .add-to-cart {
                          font-size: 0px;
                          i {
                              display: inline;
                          }
                      }
                  }
              }
          }
          .add {
              flex: 1;
              margin-top: 0;
          }
          .product-minimal-quantity {
              margin-bottom: 5px;
              color: $grey-color;
              font-style: italic;
              font-size: 14px;
          }
      }
      #product-availability {
          display: block;
          margin-top: 10px;
          text-align: right;
          i {
              font-size: 17px;
              vertical-align: middle;
          }
          .availability-txt {
              font-size: 13px;
              line-height: 18px;
              font-weight: 500;
          }
      }
    }
  }
}