// body:not(#product):not(#cms):not(#authentication):not(#my-account):not(#contact) {
//   #header {
//     .container {
//       max-width: unset !important;
//     }
//   }
//   main {
//     #wrapper {
//       max-width: unset !important;
//       padding: 0;
//       @media (max-width: 1199px) {
//         padding: 0 15px;
//       }
//       .row {
//         margin: 0;
//         #content-wrapper {
//           padding: 0;
//           #content {
//             > div,
//             > section {
//               margin-top: 190px;
//               margin-bottom: 0;
//             }
//           }
//         }
//       }
//     }
//   }
// }

body {
    #header {
      .container {
        max-width: unset !important;
      }
    }
    &:not(#product) {
      main {
        #wrapper {
        //   max-width: unset !important;
          padding: 0;
          @media (max-width: 1199px) {
            padding: 0 15px;
          }
          .row {
            margin: 0;
            #content-wrapper {
              padding: 0;
            }
          }
        }
      }
    }
    &#index {
      main {
        #wrapper {
          .row {
            #content-wrapper {
              #content {
                > div,
                > section {
                  margin-top: 190px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
}
