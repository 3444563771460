.checkout {
    bottom: $nav-mobile-height;
}

.modal-cart__name {
    color: $primary-color;
}

#checkout {
    #header {
        border: none;
        .header__logo {
            margin: 10px 0 0 10px;
        }
    }
    .header-nav {
        padding: 0 20px 0 0;
    }
    #content {
        margin-top: $margin-m;
    }
}

#promo-code {
    .form__add-voucher {
        > .input-group {
            .form-control {
                height: auto;
            }
        }
    }
}

#cart, #checkout {
    .product-line-grid-right {
        width: 100%;
        height: 100%;
        .bootstrap-touchspin {
            max-width: 160px;
            .input-group-append {
                width: auto;
                height: auto;
            }
        }
        @media(max-width: 399px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .js-cart-line-product-quantity {
        /* Firefox */
        -moz-appearance: textfield;
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        height: auto;
    }
    .delivery-option {
        background-color: $light-color-alt;
    }
    .carrier-price {
        color: $primary-color;
    }
    #footer-nav {
        // margin-bottom: 130px !important;
        // margin-bottom: calc(#{$nav-mobile-height} + 80px);
    }
}

#order-confirmation {
    #content-hook_order_confirmation {
        background-color: $masamune-grey-2 !important;
        .card-title {
            .material-icons {
                font-size: 48px;
                margin-right: 15px;
            }
        }
        &.bg-success {
            a {
                color: $light-color;
                text-decoration: underline;
                &:hover {
                    color: $light-color;
                    text-decoration: none;
                }
            }
        }
    }
    #order-items {
        .table__title-head {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}
