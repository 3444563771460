.cms-id-6 {
    .page-header, .breadcrumb {
        display: none;
    }
    #wrapper, #content {
        padding: 0;
    }
    #wrapper {
        margin: 0;
        max-width: unset;
        .row {
            margin: 0;
            #content-wrapper {
                padding: 0;
            }
        }
    }
    .page {
        display: flex;
        flex-direction: column;
        .section_title {
            font-family: $custom-font2;
            color: $masamune-black-2;
            font-size: 40px;
            font-weight: 500;
            letter-spacing: 0.1rem;
            margin-bottom: 1rem;
        }
        p {
            font-family: $custom-font;
            color: $masamune-black-2;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.1rem;
            line-height: 24px;
            margin-bottom: 0.5rem;
            strong {
                font-weight: 700;
            }
        }
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $masamune-white-2;
            border-bottom: 1px solid $masamune-red-1;
            padding: 3rem;
            flex-wrap: wrap;
            .left {
                flex: 0 0 100%;
                @media (min-width: 1420px) {
                    flex: 0 0 50%;
                }
                p {
                    &:last-child {
                        &:after {
                            content: '';
                            border-radius: 3px;
                            border: 4px solid $masamune-red-1;
                            margin: 2px 10px;
                            display: inline-block;
                        }
                    }
                }
            }
            .right {
                flex: 0 0 100%;
                @media (min-width: 1420px) {
                    flex: 0 0 40%;
                }
                img {
                    width: 100%;
                }
            }
        }
        .infos {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-bottom: 1px solid $masamune-red-1;
            padding: 8rem 3rem;
            flex-wrap: wrap;
            .location, .description, .access_transportation {
                border-left: 1px solid $masamune-black-1;
                padding-left: 1rem;
                margin: 1rem;
                p {
                    margin-bottom: 0;
                    line-height: 20px;
                    font-weight: 400;
                    letter-spacing: .06rem;
                }
                h3 {
                    margin-bottom: 1.5rem;
                    line-height: 20px;
                    font-weight: 700;
                }
            }
            .location {
                flex: 0 0 50%;
                @media (min-width: 992px) {
                    flex: 0 0 20%;
                }
            }
            .description, .access_transportation {
                flex: 0 0 50%;
                @media (min-width: 992px) {
                    flex: 0 0 30%;
                }
            }
        }
        .schedule {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $masamune-white-2;
            border-bottom: 1px solid $masamune-red-1;
            padding: 3rem;
            .img_schedule {
                width: 90%;
            }
        }
        .sports_tutors {
            padding: 3rem;
            @media (max-width: 576px) {
                padding: 1rem;
            }
            .profiles {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-wrap: wrap;
                .profile {
                    flex: 0 1 100%;
                    margin: 0 1% 1% 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: $masamune-white-2;
                    border: 1px solid $masamune-grey-2;
                    max-height: 207px;
                    @media (min-width: 992px) {
                        flex: 0 1 49%;
                    }
                    @media (min-width: 1420px) {
                        flex: 0 1 32%;
                    }
                    @media (min-width: 1920px) {
                        flex: 0 1 24%;
                    }
                    @media (max-width: 576px) {
                        flex-direction: column;
                        margin: 0 0 2% 0;
                        max-height: unset;
                        width: 100%;
                    }

                    .img_profile {
                        @media (max-width: 576px) {
                            padding: 30px 0;
                        }
                    }
                    .profile_infos {
                        padding: 0 1rem;
                        @media (max-width: 576px) {
                            padding-bottom: 2rem;
                        }
                        p {
                            letter-spacing: 0;
                            line-height: initial;
                        }
                        .sport {
                            font-size: 20px;
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                        .name {
                            font-size: 16px;
                            font-weight: 700;
                        }
                        .qualifications {
                            font-size: 16px;
                            font-weight: 400;
                        }
                        .site_link {
                            font-size: 16px;
                            font-weight: 400;
                            color: $masamune-red-2;
                        }
                    }
                }
            }
        }
        .inscription_location {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3rem;
            flex-wrap: wrap;
            .left {
                flex: 0 0 100%;
                @media (min-width: 1200px) {
                    flex: 0 0 50%;
                }
                h3 {
                    margin-top: 3rem;
                }
                p {
                    font-weight: 400;
                    letter-spacing: .06rem;
                }
            }
            .right {
                flex: 0 0 100%;
                @media (min-width: 1200px) {
                    flex: 0 0 40%;
                }
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                .img_inscription {
                    width: 100%;
                }
                a {
                    position: absolute;
                    width: 75%;
                    bottom: 5%;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 21px;
                    letter-spacing: .06rem;
                }
            }
        }
    }
    #footer {
        margin-top: 0;
    }
}