body {
    .bg-primary {
        background-color: $primary-color !important;
        color: $light-color;
    }
    .bg-secondary {
        background-color: $secondary-color !important;
        color: $light-color;
    }
    .bg-success {
        background-color: $success-color !important;
        color: $light-color;
    }
    .bg-danger {
        background-color: $error-color !important;
        color: $light-color;
    }
    .bg-warning {
        background-color: $warning-color !important;
        color: $light-color;
    }
    .bg-info {
        background-color: $info-color !important;
        color: $light-color;
    }
    .badge {
        &.badge-danger {
            background-color: $error-color !important;
            color: $light-color;
        }
    }
    .list-group-flush {
        .list-group-item {
            border-top-width: 0;
            border-left-width: 0;
            border-right-width: 0;
            border-bottom: 1px solid #C3C3C3;
            &:last-child {
                border-bottom-width: 0;
            }
        }
    }
    .product-flag {
        background-color: $primary-color;
    }
}
