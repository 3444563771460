@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700&family=Noto+Serif+TC:wght@400;500&display=swap');

// #region Icones specifiques
@font-face {
    font-family: 'Webxy-icons';
    src:  url(./webxy/fonts/icons/webxy-icons.eot?t8dyx0);
    src:  url(./webxy/fonts/icons/webxy-icons.eot?t8dyx0#iefix) format('embedded-opentype'),
    url(./webxy/fonts/icons/webxy-icons.ttf?t8dyx0) format('truetype'),
    url(./webxy/fonts/icons/webxy-icons.woff?t8dyx0) format('woff'),
    url(./webxy/fonts/icons/webxy-icons.svg?t8dyx0#webxy-icons) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@import "./fonts/icons/style.scss";
// #endregion ---------------
