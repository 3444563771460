body {
    &.scrolling {
        #header {
            .header-top {
                padding: 10px 15px;
                .header-top__col {
                    @media (min-width: 1200px) {
                        width: 150px;
                    }
                }
            }
            .menu {
                margin-top: 0;
                margin-left: 0;
            }
        }
    }
    &.has-banner {
        #header {
            top: -32px;
        }
    }
    @media (max-width: 767px) {
        #header {
            top: 0 !important;
        }
    }
    #header {
        background-color: $bg-top;
        margin-bottom: 0;
        color: $text-top;
        position: sticky;
        top: 0;
        z-index: 5;
        box-shadow: 0px 0px 5px #999;
        a {
            color: $text-header-links;
            font-size: 17px;
            font-weight: normal;
            text-transform: none;
        }
        .header-nav {
            background-color: $bg-nav;
            color: $text-nav;
            font-size: 12px;
            font-weight: 400;
            a, .btn {
                color: $text-nav;
                font-size: 12px;
                font-weight: 400;
                text-transform: none;
                @media (max-width: 479px) {
                    font-size: 14px;
                }
            }
            .header-nav__middle {
                font-size: 14px;
                font-family: $custom-font2 !important;
                font-weight: 400;
            }
            .header-nav-content {
                position: relative;
                padding: 4px 0 4px;
                display: flex;
                @media (max-width: 991px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                > * {
                    flex-shrink: 0;
                    padding: 0 40px;
                    i {
                        color: $secondary-color;
                        font-size: 18px;
                        vertical-align: middle;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
                @media (max-width: 575px) {
                    position: relative;
                }
            }
            #_desktop_contact_link {
                a {
                    color: #888;
                }
                // Module ps_contactinfo
                @media (max-width: 991px) {
                    display: none;
                }
                i[class^="xyicon-"] {
                    font-size: 19px;
                    line-height: 20px;
                    vertical-align: middle;
                    margin-right: 7px;
                }
            }
            #_desktop_phone_link {
                // Module ps_contactinfo
                font-weight: 700;
                i[class^="xyicon-"] {
                    font-size: 18px;
                    vertical-align: sub;
                    margin-right: 7px;
                }
                @media (max-width: 991px) {
                    display: none;
                }
            }
            #_desktop_language_selector {
                button {
                    color: #888;
                }
                // Module ps_languageselector
                @media (max-width: 991px) {
                    display: none;
                }
                *[data-toggle="dropdown"] {
                    font-size: 13px;
                    &:focus {
                        box-shadow: none;
                        border: 1px solid $light-color;
                        border-radius: $round-radius;
                    }
                }
                .dropdown-menu {
                    padding: 0;
                    margin-top: 10px;
                    .dropdown-item {
                        padding: 7px 22px;
                        color: $body-color;
                        &.active {
                            color: $text-dropdown-active-item;
                        }
                    }
                }
            }
            #_desktop_currency_selector {
                button {
                    color: #888;
                }
                .dropdown-menu {
                    min-width: 4rem;
                    a {
                        text-align: center;
                    }
                }
            }
            .our-warehouse-nav-container {
                // Module xyheaderbutton (voir BO)
                @media (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
            }
            .quote-link {
                // Module xyheaderbutton (voir BO)
                @media (max-width: 575px) {
                    flex: 1 1;
                }
                a {
                    background: $light-color;
                    color: $bg-nav;
                    font-size: 18px;
                    border-radius: 4px;
                    font-weight: 500;
                    @media (max-width: 1199px) {
                        font-size: 16px;
                    }
                    @media (max-width: 767px) {
                        font-size: 12px;
                        padding: 8px 10px;
                    }
                    @media (max-width: 575px) {
                        display: block;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            background-color: $bg-nav-mobile;
            color: $text-nav-mobile;
        }
        .header__logo-mobile {
            width: 75px;
            @media (max-width: 575px) {
                margin-left: 10px;
            }
        }
        #site-tagline {
            display: none;
            order: 1;
            font-size: 16px;
            @media (max-width: 767px) {
                display: inline-block;
            }
            @media (max-width: 575px) {
                font-size: 12px;
            }
        }
        #menu-icon {
            color: $text-nav;
            .material-icons {
                font-size: 38px;
            }
            @media (max-width: 991px) {
                padding: 0 15px;
            }
            @media (max-width: 767px) {
                order: 0;
            }
            @media (max-width: 575px) {
                padding: 0 10px 0 5px;
            }
            @media (max-width: 399px) {
                position: absolute;
                top: 10px;
            }
        }
        .header-top {
            flex-wrap: nowrap;
            padding: 15px;
            margin-top: 5px;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 767px) {
                padding: 5px;
                flex-wrap: wrap;
                position: relative;
            }
            @media (max-width: 479px) {
                margin-top: 0;
            }
            .header__search {
                display: none;
                @media (max-width: 767px) {
                    display: none;
                }
            }
            .header-top__col {
                width: 285px; // Logo width
                margin-right: 72px;
                flex-shrink: 0;
                transition: all .3s ease-out;
                /* @media (max-width: 1199px) {
                    width: 125px !important;
                }
                @media (max-width: 991px) {
                    margin-right: 25px;
                }
                @media (max-width: 767px) {
                    order: -1;
                    width: 60% !important;
                    padding: 0 100px;
                    margin-right: 0;
                }
                > a {
                    @media (max-width: 767px) {
                        display: block;
                        width: 230px;
                        max-width: unset;
                        margin: 0 auto 15px;
                    }
                    @media (max-width: 479px) {
                        width: 180px;
                    }
                } */
            }
            .header__logo {
                @media (max-width: 991px) {
                    margin-left: 10px;
                    order: 1;
                    flex: 2;
                }
                @media (max-width: 576px) {
                    margin-top: 9px;
                }
            }
            .header__menu {
                width: 40%;
                @media (max-width: 991px) {
                    order: 0;
                    width: auto;
                    padding: 0 20px;
                }
                #_desktop_top_menu {
                    #top-menu {
                        .menu__item {
                            a {
                                &:hover {
                                    color: $secondary-color;
                                    span::after {
                                        color: $secondary-color;
                                    }
                                }
                            }
                            &.menu__item--1 {
                                width: 20%;
                                a {
                                    span {
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .header__right {
                width: auto !important;
                display: flex;
                justify-content: space-evenly;
                margin: 0 60px 0 0;
                @media (max-width: 991px) {
                    margin: 0 20px 0 0;
                    order: 2;
                    flex: 1 0;
                    // padding: 7px 0;
                    // justify-content: flex-end;
                }
                @media (max-width: 576px) {
                    position: fixed;
                    bottom: 0;
                    background: white;
                    left: 0;
                    border-top: 1px solid black;
                    padding: 10px 0;
                    display: flex;
                    justify-content: space-around;
                    right: 0;
                    margin: 0;
                    z-index: -1;
                    box-shadow: 0px 0px 5px #999;
                }
                > * {
                    padding: 0;
                }
                .xyicon-search {
                    font-size: 35px;
                    margin-right: 60px;
                    @media (max-width: 1199px) {
                        margin-right: 30px;
                    }
                }
                .user-info {
                    // Module ps_customersignin
                    margin-right: 60px;
                    @media (max-width: 1199px) {
                        margin-right: 30px;
                    }
                    .account {
                        display: flex;
                        align-items: center;
                    }
                    img {
                        margin-right: 15px;
                        @media (max-width: 991px) {
                            margin-right: 7px;
                        }
                        @media (max-width: 575px) {
                            margin-right: 0;
                        }
                    }
                    i {
                        font-size: 40px;
                        color: $primary-color;
                        margin-right: 6px;
                    }
                    .visible--desktop {
                        font-size: 18px;
                        line-height: 1em;
                        font-weight: 300;
                        display: flex;
                        flex-direction: column;
                        @media (max-width: 1199px) {
                            display: none;
                        }
                    }
                }
                .search-widget {
                    display: none;
                }
            }
        }
        .logo {
            transition: all .5s ease-in-out;
        }
    }
}

$screen-md: 1200px;
$screen-sm-max: 991px;

li.sf-search{
    &::marker {
        content: none;
    }
    border: none;
    background: $masamune-white-1;
    a.xy-research{
        display: block;
        padding: 6px;
        @media (max-width: $screen-sm-max){
            display: none;
        }
        img{
            width: 35px;
            margin-left: 5px;
            @media (max-width: $screen-md){
                width: 32px;
            }
        }
    }
    .searchbox-container{
        height: 100%;
        width: 100%;
        display: none;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, 0.9);
        @media (max-width: $screen-sm-max){
            position: initial;
            display: block !important;
            background: none;
            margin-top: 20px;
        }
        .searchbox-content{
            position: relative;
            top: 46%;
            width: 80%;
            text-align: center;
            margin-top: 30px;
            margin: auto;
            @media (max-width: $screen-sm-max){
                width: 100%;
            }
        }
        .searchbox-closebtn{
            position: absolute;
            top: 45px;
            right: 45px;
            font-size: 60px;
            cursor: pointer;
            color: white;
            &:hover{
                color: #ccc;
            }
            @media (max-width: $screen-sm-max){
                display: none;
            }
        }
        input[type=text] {
            padding: 15px;
            font-size: 17px;
            float: left;
            height: 54px;
            width: 80%;
            background: white;
            border: 1px solid #ccc;
            &:hover{
                background: #f1f1f1;
            }
        }
        button {
            float: left;
            width: 20%;
            height: 54px;
            background: $masamune-red-1;
            color: $masamune-white-1;
            font-size: 17px;
            border: none;
            cursor: pointer;
            &:hover{
                background: $masamune-black-1;
            }
            @media (max-width: $screen-sm-max){
                &:before{
                    content: "\f002";
                    font-size: 27px;
                    font-family: "FontAwesome";
                    font-weight: normal;
                    -webkit-font-smoothing: antialiased;

                }
                span {
                    display: none;
                }
            }
        }
    }
}