#authentication, #identity {
    #main {
        margin-top: 70px;
        margin-bottom: 70px;
        .form-fields {
            .form-group {
                .input-group {
                    .input-group-btn {
                        button {
                            padding: 8px 12px;
                        }
                    }
                }
            }
            .form-group:last-child {
                label {
                    display: none;
                }
                div {
                    padding: 0;
                }
            }
        }
    }
}
