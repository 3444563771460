#product-header {
    position: sticky;
    top: 175px; // = #header height
    padding: 20px 0;
    background: $bg-body;
    z-index: 3;
    @media (max-width: $container-width-desktop-up) {
        top: 109px;
    }
    @media (max-width: 1199px) {
        top: 155px;
    }
    @media (max-width: 991px) {
        top: 100px;
    }
    @media (max-width: 767px) {
        position: relative;
        top: 0;
        z-index: 0;
    }
    // @media (max-width: 575px) {
    //     flex-direction: column;
    //     top: 95px;
    // }
    .log-in-button {
        max-width: 250px;
        display: flex;
        align-items: center;
        font-weight: 600;
    }
    .product-heading-logo {
        width: 35px;
        height: 27px;
        background-image: url('../img/webxy/heading-page.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-top: 5px;
        @media (max-width: 767px) {
            display: none;
        }
    }
    .product-header-information {
        padding-left: 10px;
        @media (max-width: 767px) {
            padding-left: 0;
        }
        h1 {
            margin-bottom: 0;
            line-height: 40px;
            font-weight: 700;
            @media (max-width: $container-width-desktop-up) {
                font-size: 32px;
            }
            @media (max-width: 767px) {
                // font-size: 40px;
            }
        }
    }
    .breadcrumb {
        margin: 0;
    }
    .product-prices {
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
            margin-top: 10px;
        }
        @media (max-width: 767px) {
            margin-top: 0;
        }
        .product__product-price {
            display: flex;
            @media (max-width: 991px) {
                justify-content: space-between;
                width: 100%;
            }
        }
    }
    .product-price {
        @media (max-width: 767px) {
            flex-direction: column;
        }
        .discount-block,
        .current-price {
            @media (max-width: 991px) {
                flex: 1;
            }
        }
        &.has-discount {
            .current-price {
                margin-right: 10px;
            }
        }
        .discount-block {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media (max-width: 1199px) {
                flex-direction: column;
            }
            @media (max-width: 991px) {
                flex-direction: row;
            }
            @media (max-width: 767px) {
                justify-content: flex-start;
            }
            .regular-price {
                font-size: 16px;
                text-decoration: none;
            }
            .discount {
                margin-left: 6px;
                padding: 0 5px;
            }
        }
        .current-price {
            @media (max-width: 767px) {
            }
            .price, .tax-label {
                font-size: 40px;
                font-weight: 700;
                line-height: 40px;
                @media (max-width: $container-width-desktop-up) {
                    font-size: 32px;
                }
                @media (max-width: 1199px) {
                    font-size: 26px;
                }
                @media (max-width: 767px) {
                    font-size: 32px;
                }
            }
            .tax-label {
                margin-left: 7px;
                @media (max-width: 767px) {
                    font-size: 20px;
                }
            }
        }
    }
}
#product {
    .breadcrumb {
        margin: 0;
    }
    .left-column {
        .block-categories {
            .category-top-menu {
                > li {
                    display: none;
                    &:first-child {
                        display: list-item;
                    }
                }
            }
        }
        .product-images {
            position: relative;
            margin-bottom: $margin-l;
            @media (max-width: 767px) {
                margin-bottom: $margin-s;
            }
            .product-thumbs {
                @media (max-width: $container-width-desktop-up) {
                    width: 100%;
                }
                .slick-arrow {
                    top: -90px;
                    &:focus {
                        box-shadow: none;
                    }
                    .product-thumb {
                        &.slick-current {
                            border-color: $primary-color;
                        }
                    }
                    &.slick-next {
                        transform: translateX(10px);
                    }
                    &.slick-prev {
                        transform: translateX(-10px);
                    }
                    > i {
                        border: 1px solid $grey-color;
                        font-size: 32px;
                        line-height: 30px;
                    }
                }
            }
            .btn-zoom {
                bottom: auto;
                top: 0;
                right: 0;
                padding-right: 13px;
                color: $primary-color;
                &:hover {
                    color: $primary-color-alt;
                }
            }
        }
    }
    [id^=main] {
        margin: 0 auto;
        max-width: 1400px;
        .product-row {
            margin-top: 15px !important;
        }
        .left-col {
            padding: 0;
            .page-content--product {
                .images-container {
                    border: none;
                    &:hover {
                        .btn-zoom {
                            display: none;
                        }
                    }
                    div {
                        .product-img {
                            position: relative;
                            padding: 15px 0;
                            &:hover {
                                .btn-zoom {
                                    display: block;
                                }
                            }
                            img {
                                border: 1px solid $masamune-grey-1
                            }
                        }
                        @media (max-width: 991px) {
                            display: flex;
                            .product-img {
                                padding: 15px;
                            }
                        }
                        @media (max-width: 576px) {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
        .right-col {
            position: sticky;
            height: fit-content;
            top: 30px;
            padding-left: 31px;
            padding-right: 0;
            @media (max-width: 991px) {
                margin-top: 0;
            }
        }
        .images-container {
            position: relative;
            border: 1px solid $grey-color-alt;
            @media (max-width: 1199px) {
                margin-bottom: 120px;
            }
            .product-thumbs {
                position: absolute;
                bottom: 30px;
                left: 0;
                right: 0;
                @media (max-width: 1199px) {
                    bottom: -120px;
                }
                .slick-slide {
                    border: 1px solid $grey-color-alt;
                    transition: border .3s ease-in;
                    cursor: pointer;
                    &:hover {
                        border-color: $grey-color;
                    }
                    &.slick-current {
                        border: 2px solid $primary-color;
                    }
                }
                .slick-arrow {
                    > i {
                        background-color: transparent;
                        color: $primary-color;
                        transition: all .3s ease-in;
                    }
                    &:hover {
                        > i {
                            color: $primary-color-alt;
                        }
                    }
                    @media (max-width: 575px) {
                        display: none !important;
                    }
                }
            }
            .btn-zoom {
                @media (max-width: $container-width-desktop-up) {
                    bottom: auto;
                    top: 1rem;
                }
            }
        }
        .product-information {
            @media (max-width: 991px) {
                margin-top: 0px;
            }
        }
        .product-reference-and-manufacturer {
            display: flex;
            align-items: center;
            margin-bottom: $margin-m;
            @media (max-width: 1199px) {
                flex-direction: column;
                align-items: flex-start;
            }
            @media (max-width: 991px) {
                // flex-direction: row;
            }
            @media (max-width: 575px) {
                flex-direction: column;
                align-items: flex-start;
            }
            .product-manufacturer {
                flex: 0 0 208px;
                max-width: 208px;
                @media (max-width: 1199px) {
                    flex: 0 0 auto;
                }
                .manufacturer-logo {
                    width: 100%;
                    box-shadow: none;
                    border: 1px solid $grey-color-alt;
                    @media (max-width: 1199px) {
                        display: block;
                        margin: 0 auto 15px auto;
                        width: 200px;
                    }
                }
                &+ .product-reference {
                    padding-left: 30px;
                    @media (max-width: $container-width-desktop-up) {
                        padding-left: 15px;
                    }
                    @media (max-width: 1199px) {
                        padding-left: 0;
                    }
                }
            }
            .product-reference {
                flex: 1 1 100%;
                > label, > span {
                    margin-bottom: 0;
                    font-size: 25px;
                    line-height: 20px;
                    @media (max-width: $container-width-desktop-up) {
                        font-size: 21px;
                    }
                }
                > span {
                    display: inline-block;
                }
                .product-anchor-link {
                    margin: 0;
                    @media (max-width: $container-width-desktop-up) {
                        font-size: 13px;
                    }
                }
            }
        }
        [id^="product-description-short"] {
            padding: 15px 0;
            p {
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0.1rem;
                color: $masamune-black-2;
                &:last-child {
                    &::after {
                        content: '';
                        border-radius: 3px;
                        border: 4px solid #c61f2f;
                        margin: 2px 10px;
                        display: inline-block;
                    }
                }
            }
            @media (max-width: 991px) {
                margin-bottom: 0;
            }
            @media (max-width: 767px) {
                max-width: 100%;
                padding-bottom: 5px;
            }
        }
        &, #modal_config {
            .product-actions {
                .product-variants {
                    .custom-select {
                        height: 60px;
                    }
                }
                .product-prices {
                    margin: 60px 0 15px;
                    .current-price-display {
                        font-size: 30px;
                        font-weight: 700;
                        color: $masamune-black-2;
                    }
                    .regular-price {
                        text-decoration: none;
                    }
                }
                @media (max-width: 991px) {
                    padding: 20px 0;
                }
                @media (max-width: 575px) {
                    padding: 20px 15px;
                }
            }
            .product-actions-2 {
                margin-top: 60px;
                .current-price-display {
                    font-size: 30px;
                    font-weight: 700;
                    color: $masamune-black-2;
                }
                .btn-configurator {
                    margin-top: 15px;
                    text-transform: uppercase;
                    font-weight: 400;
                    font-size: 24px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .product-variants {
            display: flex;
            flex-direction: column;
            label {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 12px;

                &.label-color {
                    span {
                        &.texture {
                            width: 90px;
                            height: 90px;
                        }
                    }
                }
            }
            .custom-select {
                border: 1px solid $masamune-black-1;
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23999999' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
            }
            .variant-color {
                display: flex;
                align-items: center;
                > label {
                    margin-bottom: 0;
                }
                > div {
                    &:last-child {
                        padding-left: 15px;
                    }
                }
                .label-color {
                    max-height: 32px;
                    margin-bottom: 0;
                }
            }
            &+.product-add-to-cart {
                margin-top: 14px;
            }
        }
        .product-add-to-cart {
            .quantity-add-button {
                display: flex;
                align-items: center;
                .product-quantity {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 0;
                    height: 100%;
                    width: 20%;
                    > div {
                        margin: 0;
                    }
                    .qty {
                        /* @media (max-width: $container-width-desktop-up) {
                            padding-right: 10px;
                            width: 40%;
                        }
                        @media (max-width: 1199px) {
                            width: 60%;
                        } */
                        .input-group {
                            box-shadow: none;
                            border: 1px solid $primary-color;
                            // Affichage horizontal des boutons du touchspin
                            .input-group-prepend {
                                display: none;
                                border-top-left-radius: 4px;
                                border-bottom-left-radius: 4px;
                                .btn {
                                    border-top-left-radius: 4px;
                                    border-bottom-left-radius: 4px;
                                }
                            }
                            .input-group-append {
                                //display: none;
                                border-top-right-radius: 4px;
                                border-bottom-right-radius: 4px;
                                .btn {
                                    border-top-right-radius: 4px;
                                    border-bottom-right-radius: 4px;
                                }
                            }
                            .input-group-prepend,
                            .input-group-append {
                                border: 1px solid $grey-color-alt;
                                .btn {
                                    border: none;
                                    @media (max-width: $container-width-desktop-up) {
                                        padding: 0 10px;
                                    }
                                }
                            }
                            // Affichage vertical des boutons du touchspin
                            .input-group-btn-vertical {
                                justify-content: space-around;
                                background: $light-color;
                                > .btn {
                                    padding-right: 5px;
                                }
                                > .btn,
                                > .btn > i,
                                > .btn > span {
                                    font-size: 17px;
                                    line-height: 1;
                                    font-weight: 700;
                                    color: $body-color;
                                }
                            }
                        }
                        input[name="qty"] {
                            height: 68px !important;
                            max-width: 100%;
                            text-align: center !important;
                            border: none;
                            font-size: 14px;
                            font-weight: 400;
                            padding-left: 10px;
                            padding-right: 5px;
                            text-align: center;
                            /* Firefox */
                            -moz-appearance: textfield;
                            /* Chrome, Safari, Edge, Opera */
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                            @media (max-width: $container-width-desktop-up) {
                                padding-left: 7px;
                                padding-right: 7px;
                            }
                        }
                    }
                    .add {
                        padding-left: 15px;
                        flex: 1;
                        .add-to-cart {
                            height: 50px;
                            font-size: 18px;
                            font-weight: 600;
                            padding: 10px 10px;
                            text-transform: none;
                            text-align: unset;
                            @media (max-width: 1199px) {
                                justify-content: center;
                            }
                            > i, > span {
                                font-size: 38px;
                            }
                            > .add-to-cart-txt {
                                font-size: 18px;
                                flex: 1 1 auto;
                                text-align: center;
                                @media (max-width: 1199px) {
                                    display: none;
                                }
                            }
                        }
                        @media (max-width: $container-width-desktop-up) {
                            padding-left: 10px;
                            width: 60%;
                            .add-to-cart {
                                i {
                                    display: none;
                                }
                            }
                        }
                        @media (max-width: 1199px) {
                            width: 40%;
                            .add-to-cart {
                                font-size: 0px;
                                i {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
                .add {
                    flex: 1;
                    margin-top: 0;
                    button {
                        padding: 16px;
                        font-weight: 400;
                        font-size: 24px;
                        text-transform: uppercase;
                    }
                }
                .product-minimal-quantity {
                    margin-bottom: 5px;
                    color: $grey-color;
                    font-style: italic;
                    font-size: 14px;
                }
            }
            #product-availability {
                display: block;
                margin-top: 10px;
                text-align: right;
                i {
                    font-size: 17px;
                    vertical-align: middle;
                }
                .availability-txt {
                    font-size: 13px;
                    line-height: 18px;
                    font-weight: 500;
                }
            }
        }
        .product-condition {
            display: block;
            margin-top: 10px;
            text-align: right;
        }
        #description {
            padding: $margin-l 0 $margin-l;
            margin-bottom: $margin-s;
            @media (max-width: 1199px) {
                padding-bottom: $margin-m;
            }
            @media (max-width: 767px) {
                padding: $margin-m 0 $margin-s;
                margin-bottom: 0;
            }
            .product-description {
                font-size: 18px;
            }
        }
        .product-details {
            display: flex;
            padding: $margin-s 0 $margin-m;
            margin-bottom: $margin-s;
            @media (max-width: 1199px) {
                flex-direction: column;
            }
            .product-features, .product-attachments {
                width: 50%;
                @media (max-width: 1199px) {
                    width: 100%;
                    padding: 0 !important;
                }
            }
            > section {
                &:only-child {
                    padding: 0;
                }
            }
            .product-features {
                padding-right: 15px;
            }
            .product-attachments {
                padding-left: 15px;
                .attachment {
                    display: flex;
                    align-items: center;
                    padding: 15px 20px;
                    border: 1px solid $grey-color-alt;
                    border-radius: $round-radius;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .attachment-logo {
                        flex: 0 0 60px;
                        width: 60px;
                    }
                    .attachment-detail {
                        flex: 1 1 auto;
                        a {
                            color: $text-body;
                            font-size: 18px;
                            line-height: 20px;
                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
        .data-sheet {
            display: flex;
            flex-wrap: wrap;
            .name, .value {
                margin-bottom: 15px;
                font-size: 18px;
                font-weight: 500;
            }
            .name {
                width: 40%;
                padding-right: 15px;
                > span {
                    display: block;
                    padding: 3px 7px;
                    background-color: $light-grey-color;
                }
            }
            .value {
                background-color: $light-grey-color;
                padding: 3px 7px;
                width: 60%;
            }
        }
    }
    .product-accessories, .product-linked {
        margin: 0 auto;
        padding: 0;
        overflow: hidden;
        h2 {
            font-size: 25px;
            margin-bottom: 32px;
        }
        .xy-title {
            margin: 0 auto;
        }
        .products-container {
            padding: 0 15px;
        }
        .product-miniature {
            margin: 0 20px;
            padding: 0 50px;
            max-width: 100%;
            flex-basis: auto;
        }
    }
    .product-attachments {
        margin-bottom: 160px;
        .attachment {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            transition: all .3s ease-in;
            a {
                color: $body-color;
                &:hover {
                    color: $primary-color;
                }
            }
            .attachment-logo {
                margin-right: 15px;
            }
            .attachment-detail {
                font-size: 18px;
                line-height: 20px;
                color: $body-color;
            }
        }
    }
    .product-flags {
        left: 15px;
    }
    .btn-add-to-cart__spinner {
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
    }
    .xy-title {
        margin: 60px 0 30px;
    }
    .modal_links_block {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        .caracs_link, .clients_link {
          color: $masamune-red-2 !important;
          font-size: 16px;
          font-weight: 400;
          margin-right: 30px;
          cursor: pointer;
          @media (max-width: 576px) {
            margin-bottom: 10px;
          }
        }
    }
    &.product-customizable {
        .right-col {
            top: 70px!important;
        }
        *[id^="product-description-short"] {
            padding: 10px 0;
        }
        .modal_links_block {
            margin: 10px 0 30px;
        }
        .product-prices {
            margin: 30px 0 15px !important;
        }
    }
}
#product-main {
    margin-bottom: $margin-s;
    .product-separator {
        margin-top: $margin-xl;
        height: 1px;
        padding: 0 15px;
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: $grey-color-alt;
        }
    }
}
#product-additional-information {
    margin-top: $margin-l;
    margin-bottom: $margin-l;
    padding: 14px 0;
    background-color: $light-color-alt;
    border: none;
    .product-tabs {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        > * {
            width: 50%;
            padding-left: 15px;
            padding-right: 15px;
            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: $margin-m;
            }
            h2 {
                border-bottom: 1px solid #333;
                padding-bottom: 12px;
            }
        }
        .data-sheet {
            display: flex;
            flex-wrap: wrap;
            dt {
                width: 50%;
                font-weight: normal;
                .featurevalue-logo {
                    width: 23px;
                    margin-right: 10px;
                    vertical-align: sub;
                }
                &.no-img {
                    padding-left: 33px;
                }
            }
            dd {
                width: 50%;
                font-weight: bold;
            }

        }
    }
    .product-description {
        img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
}
/** Product actions fixed in bottom **/
/* @media (max-width: 991px) {
    #product {
        #footer {
            padding-bottom: 280px;
        }
    }
} */
// #region Module productcomments
#product-comments-list-header {
}
#post-product-comment-form {
    margin-top: 30px;
}
// #endregion
@media (max-width: 575px) {
    #product {
        .product-buy-group {
            position: fixed;
            left: 0;
            right: 0;
            bottom: $nav-mobile-height;
            background: #F8F8F8;
            z-index: 9;
            padding: 15px;
            box-shadow: 0 3px 10px 0px #000000;
        }
        .product-right-column-right-header {
            flex-direction: row;
            margin-bottom: 5px;
        }
        .product-prices {
            margin-bottom: 0;
            .product__product-price {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }
        }
        .current-price {
            margin-bottom: 0;
        }
        .product-additional-info {
            display: none;
        }
        .product-availability {
            text-align: right;
        }
    }
}
