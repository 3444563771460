.alert {
    &.alert-info {
        background-color: $masamune-grey-1;
        border-color: $masamune-grey-1;
        color: $masamune-black-1;
    }
    &.alert-success {
        background-color: $success-color;
        border-color: $success-color-alt;
        color: $light-color;
    }
    &.alert-danger {
        background-color: $error-color;
        border-color: $error-color-alt;
        color: $light-color;
    }
    &.alert-warning {
        background-color: $masamune-grey-2;
        border-color: $masamune-grey-2;
        color: $light-color;
    }
}
#notifications {
    // Alertes affichées en haut de page (ex : messages d'erreur lors de la création de compte client)
    .alert {
        margin-top: $margin-s;
        margin-bottom: 0;
    }
}
