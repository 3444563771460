#index {
    #footer {
        margin-top: -1px;
    }
}
#footer {
    position: relative;
    color: $text-footer;
    font-size: 12px;
    background-color: $masamune-white-2;
    border-top: 1px solid $masamune-grey-2;

    @media (max-width: 767px) {
        margin-top: $margin-m !important;
    }
    @media (max-width: 575px) {
        font-size: 20px;
        padding-bottom: 59px;
    }
    > .container {
        max-width: 1140px;
        &#footer-before {
            padding-top: 30px;
            padding-bottom: 35px;
        }
        .social-sharing {
            justify-content: space-between;
            padding-left: 60px;
            @media (max-width: 991px) {
                margin-top: 20px;
                padding-left: 0;
            }
            .block-social-label {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin: 0;
                font-weight: 700;
                color: $masamune-black-2;
                font-size: 20px;
                letter-spacing: .13em;
                @media (max-width: 991px) {
                    flex: 0 0 25%;
                    padding: 0 15px;
                }
                @media (max-width: 576px) {
                    display: none;
                }
            }
            .block-social-links {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
                @media (max-width: 991px) {
                    justify-content: flex-start;
                    a {
                        margin: 0;
                    }
                }
                @media (max-width: 576px) {
                    justify-content: center;
                }
            }
        }
    }
    .footer-container {
        background-color: $bg-footer;
        color: $text-footer;
        padding-top: 40px;

        > .container {
            max-width: 1140px;
            > .row {
                display: flex;
                justify-content: space-between;
                &:first-child {
                    margin-bottom: 60px;
                }
                @media (max-width: 575px) {
                    margin-left: 0;
                    margin-right: 0;
                }
                // Modules greffés au hook hookDisplayFooter
                a {
                    color: $masamune-white-1;
                    &:hover {
                        color: $text-footer;
                    }
                }
                .block-contact {
                    // Module ps_contactinfo
                    display: flex;
                    flex-direction: column;
                    flex-grow: 3;
                    flex-shrink: 0;
                    @media (max-width: 767px) {
                        width: 100%;
                        margin-bottom: $margin-m;
                    }
                    #footer_store_info {
                        .dummy {
                            margin-bottom: 0.4rem;
                        }
                        a {
                            color: $masamune-white-2;
                            &:hover {
                                i {
                                    margin-right: 10px;
                                }
                            }
                            i {
                                font-size: 24px;
                                margin-right: 5px;
                                vertical-align: bottom;
                                transition: all .3s ease-in;
                            }
                        }
                        @media (max-width: 991px) {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            padding-bottom: 20px;
                            .footer__title {
                                font-size: 26px;
                            }
                            address {
                                font-size: 18px;
                            }
                            .contact-info-container,
                            .contact-links {
                                flex: 1;
                            }
                        }
                        @media (max-width: 991px) {
                            flex-direction: column;
                        }
                    }
                    .logo-footer {
                        margin-right: $margin-l;
                        width: 165px;
                        @media (max-width: 991px) {
                            width: 100px;
                            margin-right: $margin-m;
                        }
                        @media (max-width: 767px) {
                            width: 215px;
                            margin-right: $margin-l;
                        }
                        @media (max-width: 575px) {
                            width: 195px;
                            margin-right: $margin-m;
                        }
                        @media (max-width: 399px) {
                            display: none;
                        }
                    }
                    .store-information {
                        text-align: left;
                        @media (max-width: 399px) {
                            width: 100%;
                            text-align: center;
                        }
                        address {
                            font-size: 15px;
                            line-height: 20px;
                            @media (max-width: 767px) {
                                font-size: 18px;
                                line-height: 24px;
                            }
                            @media (max-width: 575px) {
                                font-size: 20px;
                                line-height: 26px;
                            }
                        }
                    }
                    .social-sharing {
                        // Module ps_socialfollow
                        display: flex;
                        align-items: center;
                        width: 100%;
                        max-width: 100%;
                        margin: 10px -12px 0 -12px;
                        padding: 0;
                        @media (max-width: 399px) {
                            margin: 0;
                            justify-content: center;
                        }
                        .social-share-btn{
                            background-color: transparent;
                            background-size: cover;
                            margin: 0 12px !important;
                            &.facebook {
                                background-image: url(../img/webxy/facebook-black.svg);
                                width: 11px;
                                height: 22px;
                                order: 1;
                            }
                            &.twitter {
                                background-image: url(../img/webxy/twitter-black.svg);
                            }
                            &.linkedin {
                                background-image: url(../img/webxy/linkedin-black.svg);
                                width: 20px;
                                height: 20px;
                                order: 0;
                            }
                            &.pinterest {
                                background-image: url(../img/webxy/pinterest-black.svg);
                            }
                            &.rss {
                                background-image: url(../img/webxy/rss-black.svg);
                            }
                            &.youtube {
                                background-image: url(../img/webxy/youtube-black.svg);
                                width: 22px;
                                height: 16px;
                                order: 3;
                            }
                            &.vimeo{
                                background-image: url(../img/webxy/vimeo.svg);
                            }
                            &.instagram{
                                background-image: url(../img/webxy/instagram-black.svg);
                                width: 20px;
                                height: 20px;
                                order: 2;
                            }
                        }
                    }
                }
                .block_newsletter {
                    form {
                        input {
                            border-top-left-radius: $round-radius;
                            border-bottom-left-radius: $round-radius;
                            border-right-width: 0;
                            @media (max-width: 575px) {
                                height: 50px;
                            }
                        }
                        .input-group-append {
                            button {
                                border-width: 1px;
                                border-color: $dark-color;
                                border-left-width: 0;
                                border-top-right-radius: $round-radius;
                                border-bottom-right-radius: $round-radius;
                                background-color: $light-color;
                                transition: all .3s ease-in;
                                span {
                                    font-size: 16px;
                                    letter-spacing: .115em;
                                }
                                i {
                                    background: $primary-color;
                                    color: $light-color;
                                    padding: 7px;
                                    border-radius: $round-radius;
                                    transition: all .3s ease-in;
                                }
                                &:focus,
                                &:active {
                                    outline: none;
                                }
                                &:hover {
                                    i {
                                        background-color: $primary-color-alt;
                                    }
                                }
                            }
                        }
                        .newsletter-conditions {
                            a {
                                text-decoration: underline;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                        #gdpr_consent {
                            label {
                                color: $text-footer !important;
                            }
                            a {
                                text-decoration: underline;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
                .linklist-block {
                    // Module linkblock
                    flex-grow: 8;
                    @media (max-width: 575px) {
                        text-align: center;
                    }
                    ul[id^="footer_sub_menu"] {
                        display: flex;
                        flex-direction: column;
                    }
                    .link-cms-page-1-2 {
                        // Page Livraison
                        order: 1;
                    }
                    .link-cms-page-2-2 {
                        // Page Mentions Légales
                        order: 2;
                    }
                    .link-cms-page-4-2 {
                        // Page A propos
                        order: 0;
                    }
                    .link-cms-page-5-2 {
                        // Page Paiement sécurisé
                        order: 3;
                    }
                }
                #block_myaccount_infos {
                    // Module ps_customeraccountlinks
                    flex-grow: 1;
                }
            }
            .copyright_link {
                font-size: 12px;
                letter-spacing: .171em;
                color: #ccc;
            }
        }
    }
    .footer__title {
        color: $masamune-white-1;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: .088em;
        margin-bottom: $margin-s;
        text-transform: unset;
        > i {
            font-size: 0.85em;
        }
        @media (max-width: $container-width-desktop-up) {
            font-size: 22px;
        }
        @media (max-width: 575px) {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 20px;
        }
    }
    ul li a,
    #footer_store_info {
        color: $masamune-white-2 !important;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: .007em;
    }
    ul {
        li {
            margin-bottom: 0.4rem;
        }
    }
    #footer-nav {
        background-color: $bg-nav-footer;
        color: $text-nav-footer;
        border-top: 1px solid #999;
        // @media (max-width: 767px) {
        //     margin-bottom: $nav-mobile-height !important;
        // }
        .container {
            display: flex;
            align-items: center;
            @media (max-width: 991px) {
                flex-direction: column;
            }
            @media (max-width: 575px) {
                flex-direction: column;
            }
            > div {
                width: 33%;
                @media (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 15px;
                    text-align: center !important;
                }
                &.copyright-block {
                    font-size: 14px;
                    text-align: center;
                    @media (max-width: 991px) {
                        order: 2;
                        margin-bottom: 0;
                    }
                    .madeby-link {
                        color: $text-nav-footer;
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                &.payment-block {
                    text-align: left;
                    @media (max-width: 991px) {
                        order: 0;
                    }
                    i {
                        display: inline-block;
                        font-size: 40px;
                        margin: 0 15px;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
                &.madeby-block {
                    font-size: 12px;
                    text-align: right;
                    a {
                        color: $text-body;
                        text-decoration: none;
                        &:hover {
                            color: $primary-color;
                            text-decoration: none;
                        }
                    }
                }
                &.copyright-block,
                &.payment-block,
                &.madeby-block {
                    @media (max-width: 575px) {
                        width: 100%;
                        text-align: center;
                        font-size: 16px;
                        margin: 10px 0;
                    }
                }
                &.social-links {
                    text-align: right;
                    .social-sharing {
                        justify-content: flex-end;
                        align-items: center;
                        @media (max-width: 767px) {
                            justify-content: center;
                        }
                        .social-share-btn {
                            margin: 0 0 0 15px;
                            background-color: unset;
                            color: $text-nav-footer;
                        }
                    }
                }
            }
        }
    }
    #footer-nav-mobile {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: $nav-mobile-height;
        width: 100%;
        background-color: $bg-nav-footer-mobile;
        color: $text-nav-footer-mobile;
        .user-info {
            // Module ps_customersignin
            .dropdown-menu {
                transform: none !important;
                position: fixed !important;
                top: auto !important;
                left: 0 !important;
                right: 0 !important;
                bottom: $nav-mobile-height !important;
                padding: .5rem 15%;
                border: none;
                border-top: 1px solid $dark-color;
                li {
                    margin: 7px 0;
                    img {
                        margin-right: 15px;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

#back-to-top {
    position: fixed;
    background: $masamune-white-2;
    color: $masamune-black-2;
    font-size: 25px;
    width: 50px;
    height: 50px;
    bottom: 1%;
    right: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $masamune-black-2;
    cursor: pointer;
    z-index: 3;
    @media (max-width: 576px) {
        bottom: 70px;
    }
}