@import "variables";

[class^="xyicon-"], [class*=" xyicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Webxy-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.xyicon-check-on {
  &:before {
    content: $xyicon-check-on;
  }
}
.xyicon-star-half {
  &:before {
    content: $xyicon-star-half;
  }
}
.xyicon-star-o {
  &:before {
    content: $xyicon-star-o;
  }
}
.xyicon-star {
  &:before {
    content: $xyicon-star;
  }
}
.xyicon-cart {
  &:before {
    content: $xyicon-cart;
  }
}
.xyicon-trash-o {
  &:before {
    content: $xyicon-trash-o;
  }
}
.xyicon-send-o {
  &:before {
    content: $xyicon-send-o;
  }
}
.xyicon-instagram {
  &:before {
    content: $xyicon-instagram;
  }
}
.xyicon-facebook {
  &:before {
    content: $xyicon-facebook;
  }
}
.xyicon-close {
  &:before {
    content: $xyicon-close;
  }
}
.xyicon-user {
  &:before {
    content: $xyicon-user;
  }
}
.xyicon-search {
  &:before {
    content: $xyicon-search;
  }
}
.xyicon-caret-up {
  &:before {
    content: $xyicon-caret-up;
  }
}
.xyicon-caret-right {
  &:before {
    content: $xyicon-caret-right;
  }
}
.xyicon-caret-down {
  &:before {
    content: $xyicon-caret-down;
  }
}
.xyicon-arrow_left {
  &:before {
    content: $xyicon-arrow_left;
  }
}
.xyicon-arrow_top {
  &:before {
    content: $xyicon-arrow_top;
  }
}
.xyicon-arrow_right {
  &:before {
    content: $xyicon-arrow_right;
  }
}
.xyicon-arrow_down {
  &:before {
    content: $xyicon-arrow_down;
  }
}

