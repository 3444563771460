body {
    background-color: $bg-body;
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .l-wrapper,
    .l-wrapper--boxed,
    main>.notifications-container {
        @media (min-width: $container-width-desktop-up) {
            max-width: $container-width-desktop;
        }
    }
    &:not(#index):not(#category):not(#product):not(#prices-drop):not(#best-sales):not(#new-products):not(.cms-id-6) {
        #main {
            margin: 70px auto;
            max-width: 1400px;
        }
    }
    .wrapper-container {
        background: $bg-body;
        padding-top: $margin-m;
        position: relative;
    }
    .breadcrumb {
        background-color: transparent;
        // border: 1px solid $secondary-color;
        // border-radius: $round-radius;
        align-items: center;
        padding: 10px 0;
        li {
            font-size: 18px;
            line-height: 1em;
            font-weight: 500;
            margin-bottom: 30px;
            @media (max-width: 767px) {
                font-size: 16px;
            }
            a, span {
                font-size: 16px;
                font-weight: 300;
                color: $masamune-black-2;
            }
            &.active {
                color: $masamune-black-1;
            }
        }
        .breadcrumb-item+.breadcrumb-item {
            padding-left: 5px;
            &::before {
                padding-right: 0px;
                margin-right: 5px;
                content: ">";
            }
        }
    }
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    input:-webkit-autofill, select:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $masamune-grey-3 inset !important;
        box-shadow: 0 0 0 30px $masamune-grey-3 inset !important;
    }
    .form-control:valid {
        border-color: $masamune-grey-2 !important;
    }
    .was-validated .custom-control-input:valid~.custom-control-label {
        color: $masamune-grey-2;
        &:before {
            border-color: $masamune-grey-2;
        }
    }
    .was-validated .custom-control-input:valid:checked~.custom-control-label {
        &:before {
            border-color: $masamune-grey-2;
            background-color: $masamune-grey-2;
            box-shadow: none;
        }
    }
    #carousel {
        margin: 0 -15px;
    }
    .xy-title {
        font-family: $custom-font2;
        margin: 0 auto 90px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        letter-spacing: .025em;
        small {
            font-family: $custom-font;
            text-transform: uppercase;
            display: inline-block;
            width: fit-content;
            font-size: 25px;
            line-height: 1.4em;
            color: $masamune-black-2;
            font-weight: 100;
        }
        p {
            font-size: 40px;
            font-weight: 500;
            text-transform: none;
            margin-bottom: 0;
            padding-bottom: 0.4em;
            color: $masamune-black-1;
            border-bottom: 1px solid $masamune-red-1;
            @media (max-width: 576px) {
                font-size: 34px;
            }
        }
        &.light {
            align-items: flex-start;
            width: 100%;
            margin: 0;
            text-align: left;
            small {
                font-size: 20px;
                font-weight: 100;
            }
            p {
                font-size: 30px;
                font-weight: 500;
                width: 100%;
            }
        }
    }
    .splide__arrow--prev, .slick-prev {
        left: 0 !important;
    }
    .splide__arrow--next, .slick-next {
        right: 0 !important;
    }
    .block_newsletter {
        border-right: 1px solid $masamune-black-1;
        padding-right: 60px;
        @media (max-width: 576px) {
            padding-right: 15px;
            margin-bottom: 20px;
        }
        > .row {
            height: 100%;
            align-items: center;
        }
        @media (max-width: 991px) {
            border-right: none;
        }
        #block-newsletter-label {
            flex: 0 0 25%;
            display: flex;
            justify-content: start;
            align-items: center;
            margin: 0;
            color: $masamune-black-2;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: .13em;
            white-space: nowrap;
            @media (max-width: 767px) {
                text-align: center;
            }
            @media (max-width: 576px) {
                flex: auto;
                padding-bottom: 10px;
            }
            small {
                font-size: 0.55em;
                margin-top: 10px;
                color: $light-grey-color;
            }
        }
        .conditions-txt {
            text-align: right;
            font-size: 12px;
            color: $light-grey-color;
            margin-left: 30%;
            @media (max-width: 767px) {
                margin-left: 0;
                text-align: center;
            }
            @media (max-width: 575px) {
                font-size: 14px;
            }
        }
        .newsletter-form-block {
            height: 28px;
            width: auto;
            flex: 1;
            form {
                height: 100%;
                .input-group {
                    height: 100%;
                    input {
                        height: 30px;
                        box-shadow: none;
                        border: none;
                        border-bottom: 1px solid $masamune-grey-2;
                        background-color: transparent;
                        padding: 0;
                        margin-right: 20px;
                    }
                    .input-group-append {
                        width: 164px;
                        height: 30px;
                        border-width: 0;
                        @media (max-width: 991px) {
                            width: auto;

                        }
                        button {
                            &::before {
                                content: none;
                            }
                            background-color: $masamune-white-1;
                            border-color: $masamune-grey-2;
                            color: $masamune-black-1;
                            padding: 0 20px;
                            transition: 0.3s all;
                            width: 100%;
                            &:hover {
                                color: $masamune-white-1;
                                background-color: $masamune-red-1;
                            }
                            span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    .featured-products {
        .products {
            article {
                margin-bottom: 0;
            }
        }
    }
    .slick-initialized {
        .slick-dots {
            display: flex;
            justify-content: center;
            > li {
                overflow: hidden;
                margin: 0 6px;
                &.slick-active {
                    *[id^="slick-slide-control"] {
                        background-color: $primary-color;
                        border-color: $light-grey-color;
                    }
                }
            }
            *[id^="slick-slide-control"] {
                text-indent: -2000px;
                border-radius: 50%;
                border: 1px solid $dark-color;
                background-color: $light-grey-color;
                width: 12px;
                height: 12px;
                padding: 0;
            }
        }
    }
    .shopping-cart {
        position: relative;
        > a {
            .blockcart__count {
                position: absolute;
                top: -10px;
                right: -20px;
                width: 20px;
                height: 20px;
                text-align: center;
                background: $secondary-color;
                color: $light-color;
                border-radius: 50%;
                line-height: 20px;
                font-size: 12px;
                @media (max-width: 1199px) {
                    top: -15px;
                    right: -8px;
                    width: 18px;
                    height: 18px;
                    font-size: 10px;
                }
            }
        }
    }
    .page-title {
        display: flex;
        flex-wrap: nowrap;
        margin: 30px 0 50px;
        .category-heading-logo {
            width: 54px;
            height: 54px;
            background-image: url('../img/webxy/heading-page.svg');
            background-repeat: no-repeat;
            background-position: center;
        }
        h1, .h1 {
            margin-bottom: 0;
            line-height: 1;
            font-weight: 700;
        }
        h1, .h1, nav {
            padding-left: 12px;
        }
    }
}

.modal-backdrop {
    &.show {
        opacity: 0.85;
    }
}

.left-column {
    // #region Module ps_categorytree
    .block-categories {
        border: 1px solid $primary-color;
        border-top-width: 0;
        padding: 0;
        box-shadow: 0px 0px 10px 0px $grey-color;
        .category-top-menu {
            > li {
                &:first-child {
                    background-color: $primary-color;
                    color: $light-color;
                    padding: 7px 10px;
                    margin-bottom: 0 !important;
                    a {
                        font-size: 18px;
                        font-weight: 500;
                        color: $light-color;
                    }
                }
            }
            > li > .category-sub-menu {
                // Niveau 1
                > li {
                    background-color: $grey-color-alt;
                    padding: 0;
                    > a, > .navbar-toggler i {
                        color: $primary-color;
                        padding-left: 10px;
                        padding-right: 10px;
                        line-height: 36px;
                        &:hover {
                            color: $primary-color-alt;
                        }
                    }
                    > a {
                        font-size: 18px;
                        font-weight: 500;
                    }
                    > .navbar-toggler {
                        transition: all .4s ease;
                        &[aria-expanded=true] {
                            transform: rotate(90deg);
                        }
                    }
                    > .collapse > .category-sub-menu,
                    > .collapsing > .category-sub-menu {
                        // Niveau 2
                        background-color: $light-color;
                        padding-left: 10px;
                        padding-right: 10px;
                        transition: all .3s ease-in-out;
                        > li {
                            > a, > .icon-collapse {
                                line-height: 36px;
                                &:hover {
                                    color: $primary-color;
                                }
                            }
                            > a {
                                font-size: 18px;
                            }
                            > .icon-collapse {
                                &[aria-expanded=true] {
                                    transform: rotate(90deg);
                                }
                            }
                            > .collapse > .category-sub-menu,
                            > .collapsing > .category-sub-menu {
                                // Niveau 3
                                padding-left: 10px;
                                padding-right: 10px;
                                > li {
                                    padding-left: 0;
                                    > a {
                                        color: $dark-grey-color;
                                        &:hover {
                                            color: $primary-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // #endregion
    .contact-us-block {
        background-color: $primary-color;
        color: $light-color;
        padding: 20px;
        text-align: center;
        border-radius: $round-radius;
        @media (max-width: 1199px) {
            h3, .h3 {
                font-size: 16px;
            }
            h2, .h2 {
                font-size: 22px;
            }
            p {
                margin-bottom: 7px;
            }
        }
    }
}

// #region Module ps_socialfollow
.social-sharing {
    > div {
        & + div {
            margin-top: 5px;
        }
    }
}
.social-share-btn {
    width: auto;
    height: auto;
    display: inline;
    vertical-align: middle;
    background-image: none;
    &::before {
        font-family: $custom-iconfont;
        display: inline-block;
        font-size: 27px;
        line-height: 1.2em;
    }
    /* &.youtube {
        &::before {
            content: "\e91a";
        }
    }
    &.instagram {
        &::before {
            content: "\e91d";
        }
    }
    &.facebook {
        &::before {
            content: "\e909";
        }
    }
    &.pinterest {
        &::before {
            content: "\e903";
        }
    }
    &.twitter {
        &::before {
            content: "\e904";
        }
    }
    &.linkedin {
        &::before {
            content: "\e902";
        }
    } */
}
// #endregion

// #region pages CMS
// #endregion

// #region Slick
.slick-arrow {
    .xyicon-arrow_left,
    .xyicon-arrow_right {
        @media (max-width: 1199px) {
            font-size: 26px;
        }
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
}
// #endregion

// #region Manufacturers
#manufacturer {
    #main {
        > ul {
            display: flex;
            flex-wrap: wrap;
            .brand {
                width: 25%;
                padding: 5px 15px;
                margin-bottom: 30px;
                .brand-content {
                    padding: 5px 10px;
                    border: 1px solid $grey-color-alt;
                    transition: all .3s ease-in-out;
                    &:hover {
                        border-color: $grey-color;
                        box-shadow: 0 0 6px 3px $light-grey-color;
                    }
                    .brand-img {
                        a {
                            display: block;
                            width: 100%;
                        }
                        img {
                            display: block;
                            height: 95px;
                            max-width: 100%;
                            margin: 0 auto;
                        }
                    }
                    .brand-infos {
                        text-align: center;
                        a {
                            color: $secondary-color;
                            font-size: 18px;
                            font-weight: 600;
                            &:hover {
                                color: $secondary-color-alt;
                            }
                        }
                    }
                    .brand-products {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                    }
                }
            }
        }
    }
}
// #endregion
