body {
    font-family: #{$custom-font}, #{$font-family-sans-serif};
    color: $text-body;
}
a {
    color: $link-color;
    transition: all .3s ease-in-out;
    &:hover {
        color: $link-color-hover;
    }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.products-section-title {
  font-family: #{$custom-font}, #{$font-family-sans-serif};
}
h1, .h1 {
    font-size: 40px;
    font-weight: 300;
    @media (max-width: 767px) {
        font-size: 34px;
    }
}
h2, .h2 {
    font-size: 28px;
    @media (max-width: 767px) {
        font-size: 24px;
    }
}
h3, .h3 {
    font-size: 20px;
    @media (max-width: 767px) {
        font-size: 16px;
    }
}
h4, .h4 {
    font-size: 17px;
    @media (max-width: 767px) {
        font-size: 13px;
    }
}
h5, .h5 {
    font-size: 14px;
    @media (max-width: 767px) {
        font-size: 12px;
    }
}
h6, .h6 {
    font-size: 12px;
    @media (max-width: 767px) {
        font-size: 10px;
    }
}
.main-title {
    margin-top: 60px;
    margin-bottom: 80px;
}
.no-margin {
  margin: 0;
}
.text-primary {
  color: $primary-color !important;
}
.text-primary-alt {
  color: $primary-color-alt !important;
}
.text-secondary {
  color: $secondary-color !important;
}
.text-secondary-alt {
  color: $secondary-color-alt !important;
}
.text-tertiary {
  color: $tertiary-color !important;
}
.text-tertiary-alt {
  color: $tertiary-color-alt !important;
}
.text-success {
  color: $masamune-grey-2 !important;
  //color: $success-color !important;
}
.text-success-alt {
  color: $success-color-alt !important;
}
.text-error {
  color: $error-color !important;
}
.text-error-alt {
  color: $error-color-alt !important;
}
.text-warning {
  color: $warning-color !important;
}
.text-warning-alt {
  color: $error-color-alt !important;
}
.cursor-pointer {
  cursor: pointer;
}
.text-lowercase {
    text-transform: lowercase;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-no-transform {
    text-transform: none;
}
.text-light {
    font-weight: 300 !important;
}
.text-medium {
  font-weight: 500 !important;
}
.text-bold {
  font-weight: bold !important;
}
.text-italic {
  font-style: italic !important;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-underline {
    text-decoration: underline;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.heading-page,
.heading-page-alt {
    font-weight: 300;
    &::before {
        content: "";
        width: 34px;
        height: 26px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 192px;
    }
}
.heading-page {
    &::before {
        background-image: url('../img/webxy/heading-page.svg');
    }
}
.heading-page-alt {
    &::before {
        background-image: url('../img/webxy/heading-page-alt.png');
    }
}
.product-headline {
    font-size: 25px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: $margin-m;
}
