$xyicon-check-on: "\e900";
$xyicon-star-half: "\e901";
$xyicon-star-o: "\e902";
$xyicon-star: "\e903";
$xyicon-cart: "\e904";
$xyicon-trash-o: "\e905";
$xyicon-send-o: "\e906";
$xyicon-instagram: "\e907";
$xyicon-facebook: "\e908";
$xyicon-close: "\e909";
$xyicon-user: "\e90a";
$xyicon-search: "\e90b";
$xyicon-caret-up: "\e90c";
$xyicon-caret-right: "\e90d";
$xyicon-caret-down: "\e90e";
$xyicon-arrow_left: "\e90f";
$xyicon-arrow_top: "\e910";
$xyicon-arrow_right: "\e911";
$xyicon-arrow_down: "\e912";